import type { NextPage } from 'next'
import styles from '../styles/Home.module.css'
import {useWindowSize} from "../hooks/useWindowSize";
import {useState} from "react";
import {requestHandler} from "../utils/requestHandler";
import {url} from "../apiVariables/urls";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useTranslation } from "next-i18next";
import HomeLayout from "../components/layouts/HomeLayout/HomeLayout";
import {mobileScreenWidth} from "../constants";
import HomePage from "../components/pages/HomePage/HomePage";
import HomeMobile from "../components/pages/HomePageMobile/HomePageMobile";
import SEO from "../components/shared/SEO/SEO";
import WithGoogleLogin from "../components/shared/WithGoogleLogin/WithGoogleLogin";
import {useEffectOnce} from "../hooks/useEffectOnce";
import {Modal} from "antd";
import HomeUploadArticleModal from "../components/shared/Modals/HomeUploadArticleModal/HomeUploadArticleModal";
import {BottomSheet} from "react-spring-bottom-sheet";
import {getCookie} from "../utils/getCookie/getCookie";

const Home: NextPage = (props: any) => {
	const { width } = useWindowSize();
	const { t } = useTranslation("common");

	// Layout
	const [nearbyLoading, setNearbyLoading] = useState(true);
	const [categoriesLoading, setCategoriesLoading] = useState(true);
	const [showRatingModal, setShowRatingModal] = useState(false);

	// Data
	const [nearbyData, setNearbyData] = useState([]);
	const [categoriesData, setCategoriesData] = useState([]);

	const getData = async () => {
		const getNearbyData = (loc:{lat: number, lng: number}) => {
			// Get nearby posts
			requestHandler({
				path: url.home.post.getNearby,
				method: "GET",
				params: {
					lat: loc.lat,
					lng: loc.lng
				}
			}).then((res: any) => {
				if (res && res.status === 200) {
					setNearbyData(res.data.data)
				}
			}).catch((e) => {
				console.error(e);
			}).finally(() => {
				setNearbyLoading(false);
			});
		}
		getNearbyData({
			lat: 48.210033,
			lng: 16.363449
		})

		// Get categories
		requestHandler({
			path: url.home.category.getPopular,
			method: "GET",
		}).then((res: any) => {
			if (res && res.status === 200) {
				setCategoriesData(res.data);
			}
		}).catch((e) => {
			console.error(e);
		}).finally(() => {
			setCategoriesLoading(false);
		});
	}

	useEffectOnce(() => {
		getData();
		const cookie = getCookie("CookieScriptConsent")
		if (cookie) {
			// If cookie accepted show banner in 3 seconds
			setTimeout(() => {
				if (!localStorage.getItem("uploadModal")) {
					setShowRatingModal(true);
				}
			}, 3000);
		} else {
			// If cookie not accepted show in 30 seconds because of conflict in layers
			setTimeout(() => {
				if (!localStorage.getItem("uploadModal")) {
					setShowRatingModal(true);
				}
			}, 30000);
		}
	});

	const onModalClose = () => {
		setShowRatingModal(false);
		localStorage.setItem("uploadModal", "true");
	}

  return (
    <div className={styles.h1}>
		<SEO
			title={t("seo.pages.home.title")}
			description={t("seo.pages.home.description")}
			customElement={<link rel="canonical" href="https://shary.at"/>}
		/>
		<WithGoogleLogin />

		{/*Upload item modal*/}

		{width && width > mobileScreenWidth
			? <Modal
				visible={showRatingModal}
				onCancel={() => {
					onModalClose()
				}}
				footer={null}
				width={900}
				closable={false}
				maskClosable={true}
				bodyStyle={{padding: "0px"}}
			>
				<HomeUploadArticleModal
					onClose={() => onModalClose()}
				/>
			</Modal>
			: <BottomSheet
				blocking={true}
				open={showRatingModal}
				skipInitialTransition
				defaultSnap={({maxHeight}) => 700}
				onDismiss={() => {
					onModalClose()
				}}
			>
				<HomeUploadArticleModal
					onClose={() => onModalClose()}
				/>
			</BottomSheet>
		}

		<HomeLayout>
			{width && width < mobileScreenWidth
				? <HomeMobile
					nearbyLoading={nearbyLoading}
					nearbyData={nearbyData}
					categoriesLoading={categoriesLoading}
					categoriesData={categoriesData}
				/>
				: <HomePage
					nearbyLoading={nearbyLoading}
					nearbyData={nearbyData}
					categoriesLoading={categoriesLoading}
					categoriesData={categoriesData}
				/>
			}
		</HomeLayout>
    </div>
  )
}

export default Home

export async function getStaticProps({ locale }:any) {

	return {
		props: {
			...(await serverSideTranslations(locale, ["common"], null, ["en"])),
		},
	};
}
