import {GoogleLogin} from "@react-oauth/google";
import {requestHandler} from "../../../utils/requestHandler";
import {url} from "../../../apiVariables/urls";
import {setToken} from "../../../utils/cookie/token.cookie";
import {useWindowSize} from "../../../hooks/useWindowSize";
import {mobileScreenWidth} from "../../../constants";
import {useAppDispatch, useAppSelector} from "../../../redux/store";
import {fetchUserById} from "../../../redux/userSlice";
const TRACKING_ID = process.env.NEXT_PUBLIC_GA4_TRACKING_ID!;

export default function WithGoogleLogin() {
	const dispatch = useAppDispatch();
	const { width } = useWindowSize();
	const user = useAppSelector((state) => state.user.user);

	const googleLogin = (googleResponse:any) => {
		requestHandler({
			path: url.auth.google,
			method: "POST",
			data: googleResponse
		})
			.then((res:any) => {
				gtag("event", "login", {
					method: "Google Popup",
					send_to: TRACKING_ID
				});
				setToken(res.data.token);
				dispatch(fetchUserById());
			})
			.catch((err:any) => {
				console.error(err)
			})
	}

	return(
		<div style={{height: "0px"}}>
			{width && width > mobileScreenWidth && !user?._id
				? <GoogleLogin
					onSuccess={googleLogin}
					onError={() => {
						//
					}}
					useOneTap
				/>
				: null
			}
		</div>
	)
}
