import styles from "./HomeUploadArticleModal.module.css";
import closeIcon from "../../../../img/home/home/close.png";
/*import bg from "../../../../img/home/home/uploadItemModal.jpeg";*/
import bg from "../../../../img/home/home/play.png";
/*import bgMobile from "../../../../img/home/home/uploadItemModalMobile.jpeg";*/
import bgMobile from "../../../../img/home/home/play-mobile.png";
import {useTranslation} from "next-i18next";
import Button from "../../Button/Button";
import {useRouter} from "next/router";
import { useEffect } from "react";
import {mobileScreenWidth} from "../../../../constants";
import {useWindowSize} from "../../../../hooks/useWindowSize";
import Link from "next/link";
const TRACKING_ID = process.env.NEXT_PUBLIC_GA4_TRACKING_ID!;

interface IHomeUploadArticleModal {
	onClose: () => void;
}

export default function HomeUploadArticleModal(props: IHomeUploadArticleModal) {
	const { t } = useTranslation();
	const router = useRouter();
	const { width } = useWindowSize();

	useEffect(() => {
		gtag("event", "marketing_banner_show", {
			method: "Upload Item",
			send_to: TRACKING_ID
		});
	}, [])

	return(
		<div
			className={styles.container}
			style={width && width > mobileScreenWidth
				? {
					backgroundImage: `url("${bg.src}")`
				}
				: {}
		}
		>
			<div
				className={styles.closeBtn}
				onClick={() => props.onClose()}
			>
				<img src={closeIcon.src} alt="close" title="close"/>
			</div>

			<div />

			<div
				className={styles.content}
			>

				{width && width > mobileScreenWidth
					? <></>
					: <img
						src={bgMobile.src}
						title={"upload item mobile"}
						alt={"upload item mobile"}
					/>
				}

				<h5>
					{/*{t("home.upload_banner.title")}*/}
					Gewinne ein Frühstück für zwei mit einer unvergesslichen Aussicht!
				</h5>

				<p>
					{/*{t("home.upload_banner.description")}*/}
					Gewinne ein Frühstück mit atemberaubender Aussicht über den Dächern von Wien!
				</p>

				<p>
					Teilnahmebedingungen:
				</p>

				<p>
					Lade bis spätestens 30. April mindestens einen Artikel auf unserer Plattform hoch.
					Bewerte unsere App im Apple Store, Play Market oder in der Google Review.
				</p>

				<p>
					Jeder hochgeladene Artikel dient als ein Ticket für unser Gewinnspiel. Je mehr Artikel du hochlädst, desto größer sind deine Chancen auf den Gewinn!
				</p>

				<div style={{flexDirection: "column", alignItems: "center", width: "100%"}}>

					<Button
						text={t("home.upload_banner.upload_btn")}
						styleType={"primary"}
						onClick={() => {
							gtag("event", "marketing_banner_click", {
								method: "Upload Item",
								send_to: TRACKING_ID
							});
							props.onClose();
							router.push("/dashboard/posts/upload/");
						}}
						style={width && width > mobileScreenWidth
							? {}
							: {width: "100%"}
						}
					/>

					<div style={width && width > mobileScreenWidth
						? {marginTop: "10px"}
						: {marginTop: "10px", display: "flex", justifyContent: "center"}}>
						<Link href={"/info/contest"}>
							Teilnahmebedingungen
						</Link>
					</div>

				</div>

				{width && width > mobileScreenWidth
					? <></>
					: <Button
						text={t("home.upload_banner.close_btn")}
						styleType={"outlined"}
						onClick={() => {
							props.onClose();
						}}
						style={{
							height: "42px",
							width: "100%",
							marginTop: "20px"
						}}
					/>
				}

			</div>

		</div>
	);
}
