import {useEffect, useState} from "react";
import styles from "./PostsCarousel.module.css";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {useWindowSize} from "../../../../hooks/useWindowSize";
import {mobileScreenWidth} from "../../../../constants";
import ProductCardLoading from "../../../shared/ProductCardLoading/ProductCardLoading";
import ProductCard from "../../../shared/ProductCard/ProductCard";

interface IProps {
	loading: boolean;
	data: any[];
	withoutDistance?: boolean;
}

const responsive = {
	superLargeDesktop: {
		// the naming can be any, depends on you.
		breakpoint: { max: 4000, min: 3000 },
		items: 4
	},
	desktop: {
		breakpoint: { max: 3000, min: 1400 },
		items: 4
	},
	desktopOne: {
		breakpoint: { max: 1400, min: 1250 },
		items: 3.5
	},
	desktopTwo: {
		breakpoint: { max: 1250, min: 1160 },
		items: 3.3
	},
	desktopThree: {
		breakpoint: { max: 1160, min: 1090 },
		items: 3.1
	},
	desktopFour: {
		breakpoint: { max: 1090, min: 1030 },
		items: 2.9
	},
	desktopFive: {
		breakpoint: { max: 1030, min: 900 },
		items: 2.7
	},
	tablet: {
		breakpoint: { max: 960, min: 900 },
		items: 2.5
	},
	tabletOne: {
		breakpoint: { max: 900, min: 840 },
		items: 2.3
	},
	tabletTwo: {
		breakpoint: { max: 840, min: 770 },
		items: 2.1
	},
	tabletThree: {
		breakpoint: { max: 770, min: 710 },
		items: 1.9
	},
	tabletFour: {
		breakpoint: { max: 710, min: 640 },
		items: 1.7
	},
	tabletFive: {
		breakpoint: { max: 640, min: 600 },
		items: 1.5
	},
	tabletSix: {
		breakpoint: { max: 600, min: 500 },
		items: 1.2
	},
	mobile: {
		breakpoint: { max: 500, min: 0 },
		items: 1
	}
};

export default function PostsCarousel(props:IProps) {
	const [carouselData, setCarouselData] = useState<any[]>([])
	const loadingArray = Array.from(Array(10).keys())
	const { width } = useWindowSize()

	useEffect(() => {
		if (props.data && props.data.length) {
			setCarouselData(props.data)
		}
	}, [props.data])

	return(
		<div
			className={styles.container}
			data-testid="home_cy_items_carousel"
		>
			<Carousel
				swipeable={width ? width < mobileScreenWidth : false}
				draggable={width ? width < mobileScreenWidth : false}
				responsive={responsive}
				autoPlay={width ? width < mobileScreenWidth : false}
				keyBoardControl={true}
				removeArrowOnDeviceType={["tablet", "mobile"]}
				dotListClass="custom-dot-list-style"
				itemClass="carousel-item-padding-40-px"
			>
				{props.loading
					? loadingArray.map((item:any, idx:number) => (
						<div key={idx.toString()} style={{marginBottom: "60px"}}>
							<ProductCardLoading />
						</div>
					))
					: carouselData.map((item:any, idx:number) => (
						<div
							key={idx.toString()}
							style={{marginBottom: "60px"}}
						>
							<ProductCard data={item} type={"homeNearby"} withoutDistance={props.withoutDistance} />
						</div>
					))
				}
			</Carousel>
		</div>
	)
}
