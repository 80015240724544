import React from "react";
import styles from "./HomeMobileContentContainer.module.css";

interface IProps {
	children: React.ReactNode
}

export default function HomeMobileContentContainer(props:IProps) {
	return(
		<main className={styles.home_mobile_content_container}>
			{props.children}
		</main>
	)
}
