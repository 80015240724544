import styles from "./HomePage.module.css"
import {useTranslation} from "next-i18next";
import bg from "../../../img/home/bg.png";
import marketingImage from "../../../img/home/marketing.png";
import paymentsImage from "../../../img/home/payments.png";
import Button from "../../shared/Button/Button";
import {Skeleton} from "antd";
import {motion} from "framer-motion";
import Link from "next/link";
import Search from "../../shared/Search/Search";
import PostsCarousel from "../../home/home/PostsCarousel/PostsCarousel";
import HowToCard from "../../home/home/HowToCard/HowToCard";
import {useRouter} from "next/router";
import React, {useEffect, useRef} from "react";
import brutkastenLogo from "../../../img/home/home/press/brutkastenlogo.svg";
import wirschaftszeitLogo from "../../../img/home/home/press/witschaftszeit.png";
import oe24Logo from "../../../img/home/home/press/oe24.png";
import wkoLogo from "../../../img/home/home/press/logo-wko.png";
import derStandardLogo from "../../../img/home/home/press/der-standard.png";
import kurrierLogo from "../../../img/home/home/press/kurrier.png";

interface IProps {
	nearbyLoading: boolean;
	nearbyData: any[];
	categoriesLoading: boolean;
	categoriesData: any[];
}

export default function HomePage(props:IProps) {
	const { t } = useTranslation();
	const { i18n } = useTranslation();
	const router = useRouter();

	const howSectionRef = useRef<any>(null);

	useEffect(() => {
		if (router.asPath.includes("how") && howSectionRef && howSectionRef.current) {
			howSectionRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}
	}, [router.asPath])

	const categoriesLoadingArray = Array.from(Array(9).keys())

	return(
		<div>
			<div
				style={{
					backgroundImage: `url("${bg.src}")`
				}}
				className={styles.home_section}
			>
				<div className={`${styles.container} ${styles.main}`}>
					<div className={styles.home_section_main_content}>

						<h2 className={styles.home_section_main_content_title}>
							{t("home.home.main_section.title")}
							<br/>
							<span>{t("home.home.main_section.title_span")}</span>
						</h2>

						<h1 className={styles.home_section_main_content_desc}>
							{t("home.home.main_section.desc")}
							<br/>{t("home.home.main_section.desc_br")}
						</h1>

						<Search />

					</div>
					<div>

					</div>
				</div>
			</div>

			<div
				className={styles.home_lease}
			>
				<h3>
					{t("home.home.known_from.title")}
					<span>.</span>
				</h3>

				<div
					className={styles.press}
				>
					<div>
						<Link href={"https://kurier.at/apa-ots/wirtschaft/sharing-economy-innovation-sharyat-startet-in-oesterreich/402606521"} target={"_blank"}>
							<img src={kurrierLogo.src} title="kurrier" alt="kurrier"/>
						</Link>

						<Link href={"https://brutkasten.com/artikel/shary-ausleihe-verleih-alltagsgegenstaende"} target={"_blank"}>
							<img src={brutkastenLogo.src} title="brutkasten" alt="brutkasten"/>
						</Link>

						<Link href={"https://www.derstandard.at/story/3000000188311/sharing-economy-innovation-sharyat-startet-in-oesterreich"} target={"_blank"}>
							<img src={derStandardLogo.src} title="der-standard" alt="der-standard"/>
						</Link>
					</div>
					<div>
						<Link href={"https://www.wirtschaftszeit.at/news/sharing-plattform-shary-startet-in-wien-durch/"} target={"_blank"}>
							<img src={wirschaftszeitLogo.src} title="wirtschafrszeit" alt="wirtschafrszeit"/>
						</Link>

						<Link href={"https://www.oe24.at/nachhaltiges-oesterreich/shary-startet-mit-einer-neuen-sharing-economy-plattform/553463706"} target={"_blank"}>
							<img src={oe24Logo.src} title="oe24" alt="oe24"/>
						</Link>

						<Link href={"https://www.wko.at/site/handeldigital/marktplaetze-online-verzeichnisse.html"} target={"_blank"}>
							<img src={wkoLogo.src} title="wko" alt="wko"/>
						</Link>
					</div>

				</div>

			</div>

			<div className={styles.home_carousel}>
				<h3>
					{t("home.home.insurance.title")}
					<span>.</span>
				</h3>

				<h4 className={styles.insurance_text}>
					{t("home.home.insurance.text_1")}
				</h4>

				<h4 className={styles.insurance_text}>
					{t("home.home.insurance.text_2")}
					<span>
					<Link href={"/info/insurance"}>
						{t("home.home.insurance.link")}
					</Link>
				</span>
				</h4>

			</div>

			<div className={styles.home_carousel}>
				<h3>
					{t("home.home.products_title")}
					<span>.</span>
				</h3>
				<PostsCarousel
					loading={props.nearbyLoading}
					data={props.nearbyData}
				/>
			</div>

			<div
				className={styles.home_categories}
				data-testid="cy_home_page_categories"
			>
				<h3>
					{t("home.home.categories_title")}
					<span>.</span>
				</h3>

				<div
					className={styles.home_categories_grid}
					data-testid="cy_home_page_categories_list"
				>

					{props.categoriesLoading || !props.categoriesData.length
						? categoriesLoadingArray.map((item:any, idx: number) => (
							<div className={styles.home_categories_card} key={idx.toString()}>
								<div className="row">
									<Skeleton.Avatar
										active
										size={"small"}
									/>
									<div style={{width: "10px"}} />
									<Skeleton.Input
										active
										size={"small"}
									/>
								</div>
							</div>
						))
						: props.categoriesData.map((item:any, key: number) => (
							<Link
								href={`/posts?category=${item.path}&city=&lat=&lng=&search=&subCategory=`}
								key={item.path}
								data-testid={`cy_home_page_categories_list_item_${key}`}
							>
								<div
									className={styles.home_categories_card}
								>
									<div className="row">
										<motion.img
											src={item.imageUrl}
											alt="CATEGORY"
										/>
										<p>
											{item.name[i18n.language]}
										</p>
									</div>
								</div>
							</Link>
						))
					}

				</div>

				<div className="center">
					<Link
						href={"category"}
						className={styles.home_categories_link}
						data-testid="cy_home_page_categories_discover_all_link"
					>
						{t("home.home.discover_all")}
					</Link>
				</div>

			</div>

			<div
				className={styles.home_marketing}
				data-testid="cy_home_page_banner"
			>
				<div className={styles.home_banner}>
					<div className="center">
						<motion.img
							src={marketingImage.src}
							alt="marketing_upload"
						/>
					</div>
					<div className={styles.home_banner_content}>
						<h3>
							{t("home.home.marketing.title.part_1")}
							<span>{t("home.home.marketing.title.span")}</span>
							{t("home.home.marketing.title.part_2")}
						</h3>
						<p>{t("home.home.marketing.text")}</p>
						<div style={{height: "20px"}} />
						<Button
							data-testid="cy_home_page_banner_button"
							text={t("home.home.marketing.btn")}
							styleType={"primary"}
							link
							linkUrl={"dashboard/posts/upload"}
						/>
					</div>
				</div>
			</div>

			<div
				className={styles.home_lease}
				id="how-it-works"
				ref={howSectionRef}
				data-testid="cy_home_page_how_it_works"
			>
				<h3>
					{t("home.home.lease.title")}
					<span>.</span>
				</h3>

				<div
					className={styles.how_to_container}
					data-testid="cy_home_page_how_it_works_list"
				>

					<HowToCard
						iconName={"select_article"}
						title={t("home.home.lease.cards.article.title")}
						text={t("home.home.lease.cards.article.text")}
						url={"info/renter"}
						data-testid="cy_home_page_how_it_works_list_item"
					/>

					<HowToCard
						iconName={"select_date"}
						title={t("home.home.lease.cards.time.title")}
						text={t("home.home.lease.cards.time.text")}
						url={"info/renter"}
					/>

					<HowToCard
						iconName={"arrange_pick"}
						title={t("home.home.lease.cards.pick.title")}
						text={t("home.home.lease.cards.pick.text")}
						url={"info/renter"}
					/>

					<HowToCard
						iconName={"return"}
						title={t("home.home.lease.cards.return.title")}
						text={t("home.home.lease.cards.return.text")}
						url={"info/renter"}
					/>

				</div>

			</div>

			<div
				className={styles.home_rent}
				data-testid="cy_home_page_rent"
			>
				<h3>
					{t("home.home.rent.title")}
					<span>.</span>
				</h3>

				<div
					className={styles.how_to_container}
					data-testid="cy_home_page_rent_list"
				>

					<HowToCard
						iconName={"upload"}
						title={t("home.home.lease.cards.upload.title")}
						text={t("home.home.lease.cards.upload.text")}
						url={"info/lender"}
						data-testid="cy_home_page_rent_list_item"
					/>

					<HowToCard
						iconName={"calendar"}
						title={t("home.home.lease.cards.days.title")}
						text={t("home.home.lease.cards.days.text")}
						url={"info/lender"}
					/>

					<HowToCard
						iconName={"respond"}
						title={t("home.home.lease.cards.respond.title")}
						text={t("home.home.lease.cards.respond.text")}
						url={"info/lender"}
					/>

					<HowToCard
						iconName={"point"}
						title={t("home.home.lease.cards.arrange.title")}
						text={t("home.home.lease.cards.arrange.text")}
						url={"info/lender"}
					/>

				</div>

			</div>

			<div
				className={styles.home_payments}
				data-testid="cy_home_page_paying"
			>
				<div className={styles.home_payments_content}>
					<h3>
						{t("home.home.payments.title")}
						<span>.</span>
					</h3>
					<h4>{t("home.home.payments.sub_title")}</h4>
					<h6>{t("home.home.payments.text_1")}</h6>
					<h6>{t("home.home.payments.text_2")}</h6>
					<h6>{t("home.home.payments.text_3")}</h6>
					<h6>{t("home.home.payments.text_4")}</h6>
				</div>
				<motion.img
					src={paymentsImage.src}
					alt="who_pays"
				/>
			</div>

		</div>
	)
}
