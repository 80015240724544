import styles from "./HowToCard.module.css";
import arrange_pick from "../../../../img/home/home/HowToCard/arrange_pick.png";
import calendar from "../../../../img/home/home/HowToCard/calendar.png";
import point from "../../../../img/home/home/HowToCard/point.png";
import respond from "../../../../img/home/home/HowToCard/respond.png";
import returnIcon from "../../../../img/home/home/HowToCard/return.png";
import select_article from "../../../../img/home/home/HowToCard/select_article.png";
import select_date from "../../../../img/home/home/HowToCard/select_date.png";
import upload from "../../../../img/home/home/HowToCard/upload.png";
import arrowIcon from "../../../../img/home/home/HowToCard/arrow.png";
import {useTranslation} from "next-i18next";
import {motion} from "framer-motion";
import Link from "next/link";

interface IProps{
	iconName: "arrange_pick" | "calendar" | "point" | "respond" | "return" | "select_article" | "select_date" | "upload",
	title: string;
	text: string;
	url: string;
	"data-testid"?: string;
}

export default function HowToCard(props: IProps) {
	const { t } = useTranslation();

	const getIcon = () => {
		switch (props.iconName) {
			case "arrange_pick":
				return arrange_pick.src
			case "calendar":
				return calendar.src
			case "point":
				return point.src
			case "respond":
				return respond.src
			case "return":
				return returnIcon.src
			case "select_article":
				return select_article.src
			case "select_date":
				return select_date.src
			case "upload":
				return upload.src
		}
	}

	return(
		<div
			className={styles.how_to_rent_card}
			data-testid={props["data-testid"]}
		>
			<motion.img
				src={getIcon()}
				alt="how_to_icon"
			/>
			<h5>{props.title}</h5>
			<p>{props.text}</p>
			<Link href={props.url}>
				<p>{t("home.home.lease.cards.btn")}</p>
				<motion.img
					src={arrowIcon.src}
					alt="arrow"
				/>
			</Link>
		</div>
	)
}
