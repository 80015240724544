import styles from "../ProductCard/ProductCard.module.css";
import { Skeleton } from "antd";

interface IProductCardLoading {
	small?: boolean;
}

export default function ProductCardLoading(props: IProductCardLoading) {
	return(
		<div
			className={styles.product_card_container}
			style={{
				width: props.small ? "240px" : "",
					height: props.small ? "250px" : ""
			}}
			data-testid="cy_posts_carousel_product_card"
		>
			<div className={styles.product_card}>
				<Skeleton.Image
					style={{
						width: "100%",
						height: "100%"
					}}
				/>
				<div className={styles.product_card_content}>
					<div className="space-between">
						<h6 className={styles.product_card_content_title}>
							<Skeleton.Input
								active
								size={"small"}
							/>
						</h6>
						<div
							data-testid="cy_product_card_loading_button_container"
						>
							<Skeleton.Button
								active
								size={"small"}
							/>
						</div>
					</div>

					<div className="space-between" style={{marginTop: "20px"}}>

						<div
							className={styles.product_card_content_footer}
							data-testid="cy_product_card_loading_bottom_container"
						>
							<Skeleton.Avatar
								active
								size={"small"}
							/>
							<div style={{width: "10px"}} />
							<Skeleton.Input
								active
								size={"small"}
							/>
						</div>

						<div
							data-testid="cy_product_card_loading_bottom_button_container"
						>
							<Skeleton.Button
								active
								size={"small"}
							/>
						</div>

					</div>

				</div>
			</div>
		</div>
	)
}
