import {useCallback, useEffect, useRef, useState} from "react";
import styles from "./Search.module.css";
import {url} from "../../../apiVariables/urls";
import searchIcon from "../../../img/components/Search/search.png";
import pinIcon from "../../../img/components/Search/pin.png";
import searchButton from "../../../img/components/Search/search_button.png";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useRouter} from "next/router";
import {motion} from "framer-motion";
import {useTranslation} from "next-i18next";
import Autocomplete from "../Autocomplete/Autocomplete";
import {requestHandler} from "../../../utils/requestHandler";
import {useWindowSize} from "../../../hooks/useWindowSize";
import {useEffectOnce} from "../../../hooks/useEffectOnce";
import transformUrlForSearch from "../../../utils/transformUrlForSearch/transformUrlForSearch";
import cutStringWithDots from "../../../utils/cutStringWithDots/cutStringWithDots";
const TRACKING_ID = process.env.NEXT_PUBLIC_GA4_TRACKING_ID!;

interface ISearch {
	test?: boolean;
}

export default function Search(props: ISearch) {
	const { t } = useTranslation();
	const { i18n } = useTranslation();
	const router = useRouter();
	const { width } = useWindowSize()

	const [search, setSearch] = useState([]);
	const [openDropdown, setOpenDropdown] = useState(false);
	const [placeholder, setPlaceholder] = useState("");

	const formik = useFormik({
		initialValues: {
			search: "",
			category: "",
			subCategory: "",
			city: "",
			lat: 0,
			lng: 0
		},
		// handle form submitting
		onSubmit: () => {
			submitNavigation();
		},
	});

	const submitNavigation = () => {
		if (formik.isValid) {
			let newUrl = window.location.protocol + "//" + window.location.host + "/posts";
			newUrl = transformUrlForSearch({
				url: newUrl,
				key: "city",
				value: formik.values.city
			});
			newUrl = transformUrlForSearch({
				url: newUrl,
				key: "search",
				value: formik.values.search
			});
			router.push(newUrl)
		}
	}

	function useOutsideAlerter(ref: any) {
		useEffect(() => {
			function handleClickOutside(event: any) {
				if (ref.current && !ref.current.contains(event.target)) {
					setOpenDropdown(false);
				}
			}
			document.addEventListener("mousedown", handleClickOutside);
			return () => {
				document.removeEventListener("mousedown", handleClickOutside);
			};
		}, [ref]);
	}

	const wrapperRef = useRef(null);
	useOutsideAlerter(wrapperRef);

	const debounce = (func: any) => {
		let timer:any;
		// @ts-ignore
		return function (...args){
			// @ts-ignore
			const context = this;
			if (timer) clearTimeout(timer);
			timer = setTimeout(() => {
				timer = null;
				func.apply(context, args);
			}, 300);
		};
	};

	const options = {
		enableHighAccuracy: true,
		timeout: 5000,
		maximumAge: 0,
	};

	const success = (pos: any) => {
		const crd = pos.coords;
	}

	const errors = (err: any) => {
		console.warn(`ERROR(${err.code}): ${err.message}`);
	}

	useEffectOnce(() => {
		if (navigator.permissions && navigator.permissions.query) {
			navigator.permissions
				.query({ name: "geolocation" })
				.then(function (result) {
					if (result.state === "granted") {
						//If granted then you can directly call your function here
						navigator.geolocation.getCurrentPosition(success);
					} else if (result.state === "prompt") {
						navigator.geolocation.getCurrentPosition(success, errors, options);
					} else if (result.state === "denied") {
					}
				});
		}
	});

	const handleSearch = (event: any) => {
		const value = event.target.value;
		requestHandler({
			path: url.home.post.search,
			method: "GET",
			params: {
				key: value
			}
		}).then((res: any) => {
			if (res && res.status === 200) {
				if (!props.test) {
					gtag("event", "search", {
						method: "Posts",
						send_to: TRACKING_ID
					});
				}
				setOpenDropdown(true);
				setSearch(res.data);
			} else {
				setSearch([]);
			}
		}).catch((e) => {
			console.error(e);
		});
	};

	const handleInputChange = useCallback(debounce(handleSearch), []);

	useEffectOnce(() => {
		// @ts-ignore
		const random = Math.round(Math.random(0, 10) * 10);
		setPlaceholder(t(`components.search.examples.${random.toString()}`));
	});

	return(
		<form
			onSubmit={formik.handleSubmit}
			data-testid="search_component"
		>

			<div
				className={styles.customer_search}
			>
				<motion.img
					style={{
						width: "20px",
						marginLeft: "15px",
						marginRight: "15px"
					}}
					src={searchIcon.src}
					alt="search"
				/>
				<div style={{position: "relative"}}>
					<input
						name={"search"}
						className={styles.search_input}
						type="text"
						onChange={(event) => {
							formik.setFieldValue("search", event.target.value, true)
							handleInputChange(event)
						}}
						placeholder={placeholder}
						value={formik.values.search}
						onBlur={formik.handleBlur("search")}
					/>
					{formik.errors.search && formik.touched.search && (
						<p
							data-testid="search_input_error"
							className={styles.customer_search_validation}
						>
							{formik.errors.search}
						</p>
					)}
				</div>

				<div
					className={styles.customer_search_vertical_line}
				/>

				<motion.img
					style={{
						width: "15px",
						marginLeft: "15px",
						marginRight: "15px"
					}}
					src={pinIcon.src}
					alt="pin"
				/>

				<div
					onBlur={formik.handleBlur("city")}
					style={{position: "relative"}}
					data-testid="autocomplete_container"
				>
					<Autocomplete
						saveAddress={(data: any) => {
							formik.setFieldValue("city", data.city, true);
							formik.setFieldValue("lat", data.lat, true);
							formik.setFieldValue("lng", data.lng, true);
						}}
						placeholder={"Wien"}
						style={{width: width && width < 740 ? "120px" : ""}}
					/>
					{formik.errors.city && formik.touched.city && (
						<p
							className={styles.customer_search_validation}
							data-testid="city_input_error"
						>
							{formik.errors.city}
						</p>
					)}
				</div>

				<button
					type={"submit"}
					data-testid="search_button"
				>
					<div className="center" style={{width: "100%"}}>
						<motion.img
							src={searchButton.src}
							alt="search"
						/>
						<p style={{color: "#ffffff"}} >{t("home.home.main_section.search.btn")}</p>
					</div>
				</button>
			</div>
			{search?.length > 0 && openDropdown ?
				<div
					className={styles.main_search_box}
					ref={wrapperRef}
					data-testid="search_list"
				>
					{search.map((element:any, idx:number) => (
						<div
							className={styles.main_search_box_item}
							key={element._id.title}
							onClick={() => {
								formik.setFieldValue("search", element.title, true)
								formik.setFieldValue("category", element.category.path, true)
								formik.setFieldValue("subCategory", element.subCategory.path, true)
								setOpenDropdown(false);
							}}
						>
							<p
								data-testid={`list_item_title_${idx}`}
							>
								{cutStringWithDots({string: element.title, limit: 40})}
							</p>
							<p>{">"}</p>
							<p
								data-testid={`list_item_category_${idx}`}
							>
								{element.category.name[`${i18n.language || "de"}`]}
							</p>
							<p>{">"}</p>
							<p
								data-testid={`list_item_sub_category_${idx}`}
							>
								{element.subCategory.name[`${i18n.language || "de"}`]}
							</p>
						</div>
					))}
				</div>
				: null
			}
		</form>
	);
}
